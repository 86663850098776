<template>
    <v-container grow d-flex flex-column flex-nowrap style="height: calc(100vh - 50px)">
        <v-row no-gutters>
            <v-col cols="12" class="d-flex flex-column align-center justify-center">
                <h3>{{ 'The page is not available.' }}</h3>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import AppConfig from "Constants/AppConfig";

    export default {
        name: "NoAccess",
        data() {
            return {
                appLogo: AppConfig.appSessionLogo
            };
        },
    }
</script>

<style scoped>

</style>